import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from "../components/Homepage.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Work Item Property Widget`}</h2>
    <PageDescription mdxType="PageDescription">
      <p>{`The Work Item Property Widget displays a card showing the property selected on your dashboards. You select
the work item and which property to display.`}</p>
      <p>{`There are times when you have a parent or central work item that contains information that you want displayed
on multiple dashboards. Instead of adding static content to each dashboard, this widget allows you to display
information from the work item and then maintain the information in one location.`}</p>
    </PageDescription>
    <FeatureCard color="dark" href="https://marketplace.visualstudio.com/items?itemName=Blockworks-com.wi-property-widget" title="Get it now" actionIcon="arrowRight" className="homepage-feature" mdxType="FeatureCard">
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      